import React from "react";
import { useState } from "react";
import emailJs from "@emailjs/browser";
import "../Components/comp/Query.css";
import Footer from "../Components/Home/Footer";
import NavBar from "../Components/NavBar";
import query from "../Utils/Assests/query.png";
import { useContext } from "react";
import Context from "../Context/Context";
import "./query.css";

export default function Query() {
  const UtilCtx = useContext(Context).util;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    message: ""
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const validateForm = () => {
    const newErrors = {};
  
    // Name validation: required, minimum 2 characters, no numbers
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must be at least 2 characters";
    } else if (!nameRegex.test(formData.name)) {
      newErrors.name = "Name cannot contain numbers or special characters";
    }
  
    // Email validation: required, valid format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
  
    // Address validation: required
    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
    }
  
    // Message validation: required, minimum 10 characters
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Restrict numbers in the name field
    if (name === "name" && /\d/.test(value)) {
      return; // Stop further execution if the name contains a number
    }
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    UtilCtx.setLoader(true);

    try {
      await emailJs.send(
        "service_x9gqsk8",
        "template_1rkvqay",
        {
          name: formData.name,
          user_email: formData.email,
          address: formData.address,
          message: formData.message,
        },
        "user_X7r11lSn4Rg1cG6RBM4kE"
      );

      setSubmitStatus("success");
      setFormData({
        name: "",
        email: "",
        address: "",
        message: ""
      });
    } catch (error) {
      setSubmitStatus("error");
      console.error("Email sending failed:", error);
    } finally {
      setIsSubmitting(false);
      UtilCtx.setLoader(false);
    }
  };
  
  return (
    <>
      <NavBar />
      <div className="flex flex-col gap-16 py-10 items-center Background">
        <div className="flex flex-col items-center ">
          <h2 className="text-[3rem] sans-sarif max500:text-[1.6rem] text-red-100">
            Have Questions?
          </h2>
          <p className="text-red-100 sans-sarif">we are always here for a good cup of coffee</p>
        </div>
        <div className="mb-10">
  <div className="bg-white flex items-center w-[50rem] px-8 shadow-2xl gap-6 max500:w-[80.5vw] rounded-lg">
    <div className="max850:hidden relative">
      <img src={query} alt="" className="shadow-md w-[20rem] h-[33rem]" />
      <div className="absolute inset-0 bg-gradient-to-tr from-yellow-100/20 to-transparent rounded-lg"></div>
    </div>
    
    <div className="h-[40rem] border border-[black] my-8 max850:hidden"></div>
    
    <div className="sans-sarif flex flex-col items-center gap-6 py-8 flex-1 relative">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-100/20 rounded-full -translate-y-12 translate-x-12 blur-2xl"></div>
      <div className="absolute bottom-0 left-0 w-24 h-24 bg-blue-100/20 rounded-full translate-y-8 -translate-x-8 blur-xl"></div>
      
      <div className="text-center space-y-2">
        <h3 className="text-3xl font-bold text-gray-800 tracking-tight">FILL IT UP!</h3>
        <p className="text-gray-500 text-sm">We'd love to hear from you</p>
      </div>
      
      <div className="w-full max-w-md space-y-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          {submitStatus === "success" && (
            <div className="p-4 rounded-xl bg-gradient-to-r from-green-50 to-green-100 border border-green-200 shadow-sm">
              <p className="text-green-800 text-center font-medium flex items-center justify-center gap-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                Your message has been sent successfully!
              </p>
            </div>
          )}

          {submitStatus === "error" && (
            <div className="p-4 rounded-xl bg-gradient-to-r from-red-50 to-red-100 border border-red-200 shadow-sm">
              <p className="text-red-800 text-center font-medium flex items-center justify-center gap-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
                Failed to send message. Please try again.
              </p>
            </div>
          )}

          <div className="space-y-5">
            {["name", "email", "address"].map((field) => (
              <div key={field} className="group">
                <label className="block text-sm font-medium text-gray-700 mb-1.5 capitalize tracking-wide">
                  {field}
                </label>
                <div className="relative">
                  <input
                    type={field === "email" ? "email" : "text"}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-3 rounded-xl border bg-white/50 backdrop-blur-sm
                      ${errors[field]
                        ? "border-red-300 bg-red-50 focus:border-red-500"
                        : "border-gray-200 hover:border-gray-300 focus:border-[#ffff56]"
                      } 
                      focus:outline-none focus:ring-2 focus:ring-yellow-200
                      transition duration-200 placeholder:text-gray-400`}
                    placeholder={`Enter your ${field}`}
                  />
                  {errors[field] && (
                    <span className="absolute right-3 top-1/2 -translate-y-1/2 text-red-500">
                      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </span>
                  )}
                </div>
                {errors[field] && (
                  <p className="mt-1.5 text-sm text-red-500 flex items-center gap-1">
                    {errors[field]}
                  </p>
                )}
              </div>
            ))}

            <div className="group">
              <label className="block text-sm font-medium text-gray-700 mb-1.5 tracking-wide">
                Message
              </label>
              <div className="relative">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows="4"
                  className={`w-full px-4 py-3 rounded-xl border bg-white/50 backdrop-blur-sm
                    ${errors.message
                      ? "border-red-300 bg-red-50 focus:border-red-500"
                      : "border-gray-200 hover:border-gray-300 focus:border-yellow-500"
                    } 
                    focus:outline-none focus:ring-2 focus:ring-yellow-200
                    transition duration-200 placeholder:text-gray-400 resize-none`}
                  placeholder="Write your message here..."
                />
                {errors.message && (
                  <span className="absolute right-3 top-3 text-red-500">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </span>
                )}
              </div>
              {errors.message && (
                <p className="mt-1.5 text-sm text-red-500 flex items-center gap-1">
                  {errors.message}
                </p>
              )}
            </div>
          </div>

          <div className="pt-4">
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full py-3 px-6 bg-gradient-to-r from-yellow-300 to-yellow-400 
                text-white font-semibold rounded-xl shadow-sm
                hover:from-yellow-500 hover:to-yellow-600 
                focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2
                disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:from-yellow-400 
                disabled:hover:to-yellow-500 transition-all duration-200
                active:scale-[0.98]"
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center gap-2">
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                  Sending...
                </div>
              ) : (
                "Send Message"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
      </div>

      <Footer />
    </>
  );
}

