import React, { useContext, useState } from "react";
import { API } from "aws-amplify";
import { Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Context from "../../Context/Context";
import PreviousSessionsMobile from "../DashBordMobile/PreviousSessionsMobile";

const formatDate = (epochDate) => {
    const date = new Date(epochDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const PreviousSessions = () => {
    const [classId, setClassId] = useState("");
    const [recordingLink, setRecordingLink] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [classTypeFilter, setClassTypeFilter] = useState("");
    const [instructorTypeFilter, setInstructorTypeFilter] = useState("");

    const Ctx = useContext(Context);
    const UtilCtx = useContext(Context).util;

    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const itemsPerPage = 5;
    const totalPages = Math.ceil(Ctx.previousClasses.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const filteredClasses = Ctx.previousClasses.filter(
        (clas) => instructorTypeFilter === "" || clas.instructorNames === instructorTypeFilter
    );

    const classTypes = Array.from(
        new Set(filteredClasses.map((clas) => clas.classType))
    );

    const getInstructor = (name) => {
        return Ctx.instructorList.find(
            (i) => i.name?.toString().trim() === name?.toString().trim()
        );
    };

    const onInstructorNameChange = async (newInstructorName, instructorId, classType, classId) => {
        UtilCtx.setLoader(true);
        try {
            await API.put("user", "/admin/edit-schedule-name/Bworkz", {
                body: { classId, instructorNames: newInstructorName, instructorId, classType },
            });

            const updatedClasses = Ctx.previousClasses.map((clas) =>
                clas.classId === classId
                    ? { ...clas, instructorNames: newInstructorName, instructorId }
                    : clas
            );

            Ctx.setPreviousClasses(updatedClasses);
        } catch (e) {
            alert(e.message);
        } finally {
            UtilCtx.setLoader(false);
        }
    };

    const onRecordingUpdate = async (e) => {
        e.preventDefault();
        UtilCtx.setLoader(true);

        try {
            if (classId.length === 0 && recordingLink.length === 0) {
                alert("Invalid Details");
                return;
            }

            await API.put("user", "/admin/edit-schedule-recording/Bworkz", {
                body: { classId, recordingLink },
            });

            const updatedClasses = Ctx.previousClasses.map((clas) =>
                clas.classId === classId ? { ...clas, recordingLink } : clas
            );

            Ctx.setPreviousClasses(updatedClasses);
            setClassId("");
            alert("Updated");
        } catch (e) {
            alert(e.message);
        } finally {
            UtilCtx.setLoader(false);
        }
    };

    const sortedPreviousClasses = [...Ctx.previousClasses].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    if (isMobileScreen) {
        return <PreviousSessionsMobile />;
    }

    return (
        <div className="w-full flex flex-col items-center pt-6">
            <h2 className="text-[1.6rem] sans-sarif font-[700] mb-6">Previous Sessions</h2>

            <div className="w-[80%] flex justify-start">
                <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="filter-button bg-[#FDCF08] w-[8rem] tracking-[1.3px] text-[1.1rem] m-[1rem] mr-0 ml-12 rounded-[0.2rem] text-white"
                >
                    Filters
                </button>
            </div>

            <div className="flex flex-col-reverse w-[85%]">
                <div className={`filters ${showFilters ? "show" : ""}`}>
                    <div className="w-[95%] flex justify-end m-[0.8rem] gap-3">
                        <label className="font-bold" htmlFor="instructorTypeFilter">Instructor: </label>
                        <select
                            id="instructorTypeFilter"
                            value={instructorTypeFilter}
                            onChange={(e) => setInstructorTypeFilter(e.target.value)}
                            className="rounded-[0.51rem] px-4 bg-[#eceaeaa1]"
                        >
                            <option value="">All</option>
                            {Array.from(
                                new Set(Ctx.previousClasses.map((clas) => clas.instructorNames))
                            ).map((name) => (
                                <option key={name} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="w-[95%] flex justify-end m-[0.8rem] gap-3">
                        <label className="font-bold" htmlFor="classTypeFilter">Classes: </label>
                        <select
                            id="classTypeFilter"
                            value={classTypeFilter}
                            onChange={(e) => setClassTypeFilter(e.target.value)}
                            className="rounded-[0.51rem] px-4 bg-[#eceaeaa1]"
                        >
                            <option value="">All</option>
                            {classTypes.map((type) => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {(Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor") && classId && (
                <form className="flex gap-6 w-[88%] Sansita">
                    <input
                        placeholder="Recording Link"
                        value={recordingLink}
                        onChange={(e) => setRecordingLink(e.target.value)}
                        className="bg-[#eceaeaa1] text-#0008sans-sarif px-4 py-1 rounded-lg w-[85%]"
                    />
                    <button
                        onClick={onRecordingUpdate}
                        className="sans-sarif bg-[#FDCF08] rounded-lg py-2 w-[6rem]"
                    >
                        Update
                    </button>
                </form>
            )}

            <div className="relative bg-[#eceaeaa1] pb-[3rem] w-[75%] flex flex-col rounded-3 items-center justify-start pt-6">
                <div className="w-[96%] flex flex-col items-center justify-center p-2">
                    <div className="flex w-[85%] justify-between mb-3 font-bold">
                        <p className="overflow-hidden ml-[1rem] w-[3.7rem]">Date</p>
                        <p className="w-[25%] overflow-hidden text-center">Instructor</p>
                        <p className="w-[25%] text-center ml-[1rem] overflow-hidden">Class</p>
                        <p className="w-[7.3rem]">Recording Link</p>
                    </div>
                </div>

                <div className="overflow-auto flex flex-col gap-2 w-[100%] items-center">
                    {sortedPreviousClasses
                        .slice(startIndex, endIndex)
                        .filter((clas) => !instructorTypeFilter || clas.instructorNames === instructorTypeFilter)
                        .filter((clas) => !classTypeFilter || clas.classType === classTypeFilter)
                        .map((clas) => (
                            <div key={clas.classId} className="w-[96%] flex flex-col items-center justify-center p-2">
                                <div className="flex w-[85%] justify-between mb-[1rem] relative">
                                    <p className="overflow-hidden w-[5.7rem]">{formatDate(parseInt(clas.date))}</p>

                                    <div className="min-w-[8rem] sm:w-24 md:w-32">
                                        {Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor" ? (
                                            <select
                                                value={clas.instructorNames}
                                                onChange={(e) => onInstructorNameChange(
                                                    e.target.value,
                                                    getInstructor(e.target.value).name,
                                                    clas.classType,
                                                    clas.classId
                                                )}
                                                className="w-full rounded-lg px-2 py-1 bg-[#e4e1d7]"
                                            >
                                                {Ctx.instructorList.map((i) => (
                                                    <option key={i.name} value={i.name}>{i.name}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            clas.instructorNames
                                        )}
                                    </div>

                                    <div className="min-w-[10rem] text-center sm:w-40 md:w-56">{clas.classType}</div>

                                    <div className="w-[3.3rem] mr-[1rem] rounded px-2 bg-[#eceaeaa1] max-h-[1.8rem] self-center flex justify-center items-center">
                                        {clas.recordingLink ? (
                                            <a
                                                href={clas.recordingLink}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-black no-underline px-2 py-1 rounded-md sans-sarif"
                                            >
                                                Watch
                                            </a>
                                        ) : (
                                            <div>
                                                {Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor" ? (
                                                    <div>
                                                        {classId === clas.classId ? (
                                                            <button
                                                                onClick={() => {
                                                                    setClassId("");
                                                                    setRecordingLink("");
                                                                }}
                                                                className="px-2 py-1 bg-[#FDCF08] rounded-md text-white sans-sarif text-[0.9rem]"
                                                            >
                                                                Cancel
                                                            </button>
                                                        ) : (
                                                            <button
                                                                onClick={() => {
                                                                    setClassId(clas.classId);
                                                                    setRecordingLink(clas.recordingLink);
                                                                }}
                                                                className="w-[3rem] px-2 py-1 rounded-md sans-sarif text-white bg-[#FDCF08]"
                                                            >
                                                                Add
                                                            </button>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <p className="text-white mt-2">None</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="flex items-end justify-center mt-4">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(_, value) => setCurrentPage(value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default PreviousSessions;