// Functional Imports
import React, { useState, useContext, useEffect } from "react";
import { API } from "aws-amplify";
import { useMediaQuery } from "@mui/material";

// Icon and Style Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UsersList.css";

// Local Components Import
import UsersListMobile from "../DashBordMobile/UsersListMobile";
import Pagination from "@mui/material/Pagination";
import Context from "../../Context/Context";
import UserModal from "./modals/UserModal";



const UsersList = ({ userCheck, setUserCheck }) => {

  //Context States
  const Ctx = useContext(Context);
  const UtilCtx = useContext(Context).util;

  //User Details States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("Active");
  const [balance, setBalance] = useState("");
  const [cognitoId, setCognitoId] = useState("");
  const [currentMonthZPoints, setCurrentMonthZPoints] = useState(0);
  const [lastMonthZPoints, setLastMonthZPoints] = useState(0);
  const [joiningDate, setJoiningDate] = useState(0);

  // Other States
  const [isUserAdd, setIsUserAdd] = useState(false);
  const [userStatus, setUserStatus] = useState("Active");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [isOpen, setIsOpen] = useState(false);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const itemsPerPage = isMobileScreen ? 5 : 8;
  const [totalPages, setTotalPages] = useState(
    Math.ceil(Ctx.userList.length / itemsPerPage)
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filterUsersByStatus = (status) => {
    if (status === "all") return Ctx.userList;
    return Ctx.userList.filter((user) => user.status === status);
  };

  const handleToggleUserAdd = () => {
    setUserCheck((prevState) => (prevState === 1 ? 0 : 1));
    setIsUserAdd((prevState) => !prevState);
  };

  const availableStatuses = [
    "all",
    ...Array.from(new Set(Ctx.userList.map((user) => user.status))),
  ];

  const formatDate = (epochDate) => {
    const timestamp = parseInt(epochDate);
    if (isNaN(timestamp)) return "N/A";

    const date = new Date(timestamp);
    if (isNaN(date.getTime())) return "N/A";

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const filter2 = filterUsersByStatus(userStatus);
  const filterList = filter2.filter((user) => {
    return (
      user?.userName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.emailId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.phoneNumber?.includes(searchQuery)
    );
  });
  useEffect(() => {
    setTotalPages(Math.ceil(filterList.length / itemsPerPage));
  }, [filterList]);
  const filteredUserList = filterList.slice(startIndex, endIndex);

  const onUpdateUser = async () => {
    UtilCtx.setLoader(true);
    try {
      await API.patch("user", "/admin/update-user", {
        body: {
          cognitoId,
          emailId: email,
          userName: name,
          phoneNumber,
          status,
          balance,
          currentMonthZPoints,
          lastMonthZPoints,
        },
      });
      const updatedUsers = Ctx.userList.map((item) =>
        item.cognitoId === cognitoId
          ? {
              ...item,
              emailId: email,
              userName: name,
              phoneNumber,
              status,
              balance,
              currentMonthZPoints,
              lastMonthZPoints,
            }
          : item
      );
      Ctx.setUserList(updatedUsers);
      alert("User Updated");
    } catch (e) {
      console.error(e);
    } finally {
      UtilCtx.setLoader(false);
    }
  };

  const onCreateUser = async () => {
    UtilCtx.setLoader(true);
    try {
      await API.post("user", "/admin/create-user", {
        body: {
          emailId: email,
          userName: name,
          phoneNumber,
          status,
          balance,
          name,
        },
      });
      Ctx.setUserList([
        ...Ctx.userList,
        {
          emailId: email,
          userName: name,
          phoneNumber,
          status,
          balance,
        },
      ]);
      alert("User Added");
    } catch (e) {
      console.error(e);
    } finally {
      UtilCtx.setLoader(false);
    }
  };

  const sendInvoice = async (cognitoId) => {
    UtilCtx.setLoader(true);
    const pa = "Bworkz@ybl";
    const pn = "Bworkz";
    const am = 10;
    try {
      const res = await API.post("user", `/user/send-email/Bworkz`, {
        body: {
          pa,
          pn,
          am,
          cognitoId,
        },
      });
      console.log(res);
      alert(res.message);
      UtilCtx.setLoader(false);
    } catch (e) {
      console.error("Send reminder error:", e);
      alert("Failed to send reminder");
      UtilCtx.setLoader(false);
    }
  };
  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  if (isMobileScreen) {
    return (
      <UsersListMobile
        requestSort={requestSort}
        userCheck={userCheck}
        setUserCheck={setUserCheck}
        isUserAdd={isUserAdd}
        setIsUserAdd={setIsUserAdd}
        handleToggleUserAdd={handleToggleUserAdd}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        status={status}
        setStatus={setStatus}
        balance={balance}
        setBalance={setBalance}
        onCreateUser={onCreateUser}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        userStatus={userStatus}
        setUserStatus={setUserStatus}
        availableStatuses={availableStatuses}
        filteredUserList={filteredUserList}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        formatDate={formatDate}
        cognitoId={cognitoId}
        setCognitoId={setCognitoId}
        onUpdateUser={onUpdateUser}
        sendInvoice={sendInvoice}
        currentMonthZPoints={currentMonthZPoints}
        lastMonthZPoints={lastMonthZPoints}
        setCurrentMonthZPoints={setCurrentMonthZPoints}
        setLastMonthZPoints={setLastMonthZPoints}
        joiningDate={joiningDate}
        setIsOpen={setIsOpen}
        isOpen
      />
    );
  }

  return (
    <div className="w-[99%] flex flex-col items-center pt-6 max536:pt-0 gap-10">
      {isOpen && (
        <UserModal
          cognitoId={cognitoId}
          name={name}
          email={email}
          phoneNumber={phoneNumber}
          status={status}
          balance={balance}
          lastMonthZPoints={lastMonthZPoints}
          currentMonthZPoints={currentMonthZPoints}
          setCurrentMonthZPointsState={setCurrentMonthZPoints}
          setLastMonthZPointsState={setLastMonthZPoints}
          joiningDate={joiningDate}
          isCreate={isUserAdd}
          isUpdate={!isUserAdd}
          setNameState={setName}
          setEmailState={setEmail}
          setPhoneNumberState={setPhoneNumber}
          setStatusState={setStatus}
          setBalanceState={setBalance}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUpdateUser={onUpdateUser}
          handleCreateUser={onCreateUser}
          sendInvoice={sendInvoice}
        />
      )}
      <div className="w-[100%] pt-6 max536:pt-0">
        {/* Add User Button and Status Filter */}
        <div className="flex justify-between items-center ml-[9%] mb-0">
          <button
            className="bg-[#FDCF08] py-2 w-[8rem] rounded-[0.2rem] text-white"
            onClick={() => {
              setIsOpen(true);
              setIsUserAdd(true);
            }}
          >
            Add New Member
          </button>
          <div className="flex gap-3 items-center mr-[8rem] mb-0">
            <label className="font-bold" htmlFor="userStatusFilter">
              User Status:
            </label>
            <select
              className="rounded-[0.51rem] px-4 bg-[#eceaeaa1]"
              id="userStatusFilter"
              value={userStatus}
              onChange={(e) => setUserStatus(e.target.value)}
            >
              {availableStatuses.map((status) => (
                <option key={status} value={status}>
                  {status === "all" ? "All" : status}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Users List */}
      <div className="w-[85%] bg-[#eceaeaa1] max536:bg-transparent max536:w-[100%] rounded-3xl p-4 flex flex-col items-center max1050:w-[94vw] mx-[2.5%]">
        <h2 className="pl-5 font-sans text-[1.4rem] max536:mb-3 max536:text-[1.7rem] sans-serif max536:bg-[#FDCF08] font-bold">
          Members List
        </h2>

        {/* Search Bar */}
        <div className="flex w-[94.5%] max1050:w-[30rem] bg-[#eceaeaa1] rounded-md overflow-hidden gap-2">
          <input
            className="flex-1 p-2 outline-none rounded-md"
            type="text"
            placeholder="Search members by name, email or phone no."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            className="bg-[#FDCF08] text-white p-2 rounded-md w-20"
            onClick={() => setSearchQuery("")}
          >
            Clear
          </button>
        </div>

        {/* Users Table */}
        <div className="overflow-x-auto w-full">
          <ul className="relative px-0 pb-[3rem] w-[95%] max-w-[1700px] mx-auto flex flex-col max536:bg-[#FDCF08] rounded-3xl items-center justify-start pt-6 max536:gap-3 max536:h-[calc(100vh-16rem)] max536:bg-gradient-to-b max536:from-[#dad7c6] max536:to-[#fdd00891]">
            {/* Table Header */}
            <li className="w-full flex flex-col items-center justify-center p-2 max536:pt-5 max536:rounded-2xl">
              <div className="d-flex justify-content-between w-[98%] max1050:w-[100%] mb-3 font-bold">
                <div className="w-[18%]">Name</div>
                <div
                  className="w-[18%] email-hover"
                  onClick={() => requestSort("email")}
                  style={{ cursor: "pointer" }}
                >
                  Email
                </div>
                <div className="w-[15%] font-sans ml-[0.5rem]">Phone</div>
                <div className="w-[14%] font-sans mr-2">Country</div>
                <div className="w-[14%] font-sans">Joining Date</div>
                <div className="w-[18%] font-sans">Attendance</div>
                <div className="w-[8%] font-sans absolute right-[0rem]">
                  Due
                </div>
              </div>
            </li>

            {/* Users List */}
            <div className="overflow-auto max536:w-[96%] w-full">
              {filteredUserList.map((user) => (
                <li
                  key={user.cognitoId}
                  className="w-full flex flex-col gap-[4px] items-center justify-center p-2 max536:bg-[#FDCF08] max536:pt-6 max536:rounded-2xl Sansita max536:text-[0.8rem]"
                >
                  <div className="flex justify-between w-[100%]">
                    <div className="w-[20%] font-[400] font-sans truncate">
                      {user.userName}
                    </div>
                    <div
                      className="w-[18%] font-[400] ml-[2rem] font-sans email-hover"
                      style={{ cursor: "pointer" }}
                      title={user.emailId}
                    >
                      {user.emailId.split("@")[0]}@
                    </div>
                    <div className="w-[15%] font-[400] font-sans ml-[4.2rem]">
                      {user.phoneNumber}
                    </div>
                    <div className="w-[18%] ml-[3rem] font-[400] font-sans max536:hidden">
                      {user.country}
                    </div>
                    <div className="w-[16%] ml-[1rem] font-[400] font-sans">
                      {formatDate(user.joiningDate)}
                    </div>
                    <div className="w-[15%] font-[400] ml-[4rem] font-sans overflow-hidden">
                      {user.currentMonthZPoints || 0}/
                      {user.lastMonthZPoints || 0}
                    </div>
                    <div className="w-[8%] h-7 rounded px-2 ml-[2rem] bg-[#FDCF08] text-center">
                      {user.balance}
                    </div>
                    <button
                      className="pl-[0.4rem]"
                      onClick={() => {
                        setIsOpen(true);
                        setIsUserAdd(false);
                        setCognitoId(user.cognitoId);
                        setName(user.userName);
                        setEmail(user.emailId);
                        setPhoneNumber(user.phoneNumber);
                        setStatus(user.status);
                        setBalance(user.balance);
                        setCurrentMonthZPoints(user.currentMonthZPoints || 0);
                        setLastMonthZPoints(user.lastMonthZPoints || 0);
                        setJoiningDate(user.joiningDate);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} size="sm" />
                    </button>
                  </div>
                </li>
              ))}
              <div className="absolute bottom-0 flex justify-center items-center w-full">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  style={{ margin: "0 auto" }}
                />
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
