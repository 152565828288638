import { useState, useCallback, useEffect } from "react";
import { API } from "aws-amplify";

export const useVideoFetch = () => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastKey, setLastKey] = useState(null);

  // Wrap setVideos to add logging
  const setVideosWithLogging = useCallback((updater) => {
    console.log('setVideos called with:', typeof updater);
    if (typeof updater === 'function') {
      setVideos((prevState) => {
        console.log('Previous videos state:', prevState);
        const newState = updater(prevState);
        console.log('New videos state:', newState);
        return newState;
      });
    } else {
      console.log('Direct videos state update:', updater);
      setVideos(updater);
    }
  }, []);

  const fetchVideos = useCallback(
    async (reset = false) => {
      if (!hasMore && !reset) {
        console.log('No more videos to fetch and reset is false');
        return;
      }

      console.log('Fetching videos with params:', {
        reset,
        lastKey,
        hasMore
      });

      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          pageSize: "20",
          ...(lastKey && !reset && { lastKey }),
        });

        console.log('Making API request with query params:', queryParams.toString());

        const response = await API.get(
          "user",
          `/user/fetch-videos?${queryParams.toString()}`
        );

        console.log('API Response:', response);

        if (response.videos && Array.isArray(response.videos)) {
          setVideosWithLogging((prev) => {
            const newVideos = reset ? response.videos : [...prev, ...response.videos];
            console.log('Updating videos state:', {
              previousCount: prev.length,
              newCount: newVideos.length,
              wasReset: reset
            });
            return newVideos;
          });
          
          setLastKey(response.lastKey);
          setHasMore(!!response.lastKey);
          
          console.log('Updated state:', {
            lastKey: response.lastKey,
            hasMore: !!response.lastKey
          });
        } else {
          console.error("Unexpected response format:", response);
          setError("Unexpected response format");
        }
      } catch (err) {
        console.error("Error fetching videos:", err);
        setError("Failed to fetch videos");
      } finally {
        setLoading(false);
      }
    },
    [lastKey, hasMore, setVideosWithLogging]
  );

  useEffect(() => {
    console.log('Initial videos fetch');
    fetchVideos(true);
    // eslint-disable-next-line
  }, []);

  return {
    videos,
    setVideos: setVideosWithLogging,
    error,
    loading,
    hasMore,
    lastKey,
    fetchVideos,
  };
};