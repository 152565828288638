import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";

const PaymentSuccessful = () => {
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = '/';
        }, 1000);

        // Cleanup timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="min-h-[150vh] bg-gray-50 flex flex-col">
            <NavBar />
            <div className="flex-grow flex flex-col items-center justify-center text-center px-4 py-16">
                <h1 className="text-5xl font-bold text-gray-800 mb-6">
                    Payment Successful
                </h1>
                <p className="text-2xl text-gray-600 mb-12 max-w-2xl">
                    Your subscription is now active. Enjoy your premium features!
                </p>
                <a
                    href="/"
                    className="px-12 py-3 no-underline bg-green-300 tracking-wide text-black text-xl font-semibold rounded-lg hover:bg-yellow-400 transition-colors duration-300 inline-flex items-center gap-3"
                >
                    Get Started
                </a>
            </div>
            <Footer />
        </div>
    );
};

export default PaymentSuccessful;