import { useContext, useState } from "react";
import { Auth, API } from "aws-amplify";
import { toast } from "react-toastify";
import Context from "../../../Context/Context";
import { Link, useNavigate } from "react-router-dom";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { HR } from "flowbite-react";
import {
  EmailInput,
  PasswordInput,
  PrimaryButton,
} from "../../../common/Inputs";
import { FormWrapper } from "../../../common/Layouts";

const customTheme = {
  hrLine: "my-4 h-px w-64 border-0 bg-gray-700 dark:bg-gray-200",
};

const defaultCredentials = {
  member: { email: "bworkzmember@gmail.com", password: "Password@123" },
  instructor: { email: "bworkzinstructor@gmail.com", password: "Password@123" },
  admin: { email: "bworkzadmin@gmail.com", password: "Password@123" },
};

const AuthPage = () => {
  const { setLoader } = useContext(Context).util;
  const InstitutionId = "Bworkz";
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    setShowForm(selectedRole !== "");
    if (selectedRole) {
      setCredentials(defaultCredentials[selectedRole]);
    } else {
      setCredentials({ email: "", password: "" });
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoader(true);

    try {
      const exists = await API.post(
        "user",
        `/any/user-exists/${InstitutionId}`,
        {
          body: {
            userPoolId:
              process.env.REACT_APP_STAGE === "PROD"
                ? process.env.REACT_APP_PROD_USER_POOL_ID
                : process.env.REACT_APP_DEV_USER_POOL_ID,
            username: credentials.email,
          },
        }
      );

      if (exists.inCognito && exists.inDynamoDb && exists.inInstitution) {
        await Auth.signIn(credentials.email, credentials.password);
        setLoader(false);
        
        // Redirect to dashboard and reload the page
        window.location.href = "/dashboard";
      } else {
        setLoader(false);
        toast.error("Account does not exist");
        navigate("/signup");
      }
    } catch (e) {
      console.log(e);
      if (e.name === "NotAuthorizedException")
        toast.error("Incorrect password");
      else toast.error("Unknown error occurred");
    } finally {
      setLoader(false);
    }
  };

  return (
    <FormWrapper heading="Login">
      <form className="flex flex-col items-center gap-6 w-full">
        {/* Role Selection Dropdown */}
        <select
          className="w-full p-2 border rounded-md"
          onChange={handleRoleChange}
        >
          <option value="">Select Role</option>
          <option value="member">Member</option>
          <option value="instructor">Instructor</option>
          <option value="admin">Admin</option>
        </select>

        {showForm && (
          <>
            <EmailInput
              name="email"
              className="rounded w-full"
              value={credentials.email}
              readOnly
            />
            <PasswordInput
              name="password"
              className="rounded w-full"
              value={credentials.password}
              readOnly
            />
            <Link to={"/forgot-password"}>Forgot password?</Link>
            <PrimaryButton onClick={handleLogin}>Continue</PrimaryButton>
          </>
        )}
      </form>
    </FormWrapper>
  );
};

export default AuthPage;
